.celebration-animation {
  font-size: 5rem;
  animation: celebrate 2s ease-in-out infinite;
}

@keyframes celebrate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
