/* App.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #E7E1CC; /* Background for the entire app */
}

#root {
  height: 100%; /* Ensure the React app container fills the full height */
}
